import * as React from "react"
import { graphql } from 'gatsby'

import Layout from 'templates/Layout';
import Headline from "global/Headline";
import { default as Card} from 'components/GridCard';
import { default as Statistics} from 'templates/projects/ProjectStatistics';
import { default as AdditionalContent} from 'templates/projects/ProjectAdditionalContent';
import { default as Quote} from 'templates/projects/ProjectQuote';
import DataProvider from 'providers/data';

const ProjectDetail = props => {
	const { edges } = props.data.allProjectsProject;
	const nodes = edges.map(({node}) => node);
	let project = nodes[0];
	if ((props.providerResponse.data.length > 0 || !!project !== false) && props.providerStatusText === 'LOADED') {
		project = props.providerResponse.data[0];
	}

	const headlineObj = {
		title: project?.title,
		description: project?.longDescription ? project?.longDescription : project?.description,
		body: project?.longDescription ? project?.longDescription : project?.description,
		image: project?.image,
		columnSize: 5,
		link: "/case-studies/",
		linkText: "Back to Case Studies"
	}

	const statsObj = {
		title: project?.statsTitle,
		body: project?.statsBody,
		stats: project?.stats
	}

	const additionalContentObj = {
		title: project?.additionalTitle,
		subTitle: project?.additionalSubTitle,
		content: project?.additionalContent
	}

	const quoteObj = {
		quote: project?.quote,
		quotee: project?.quotee,
		image: project?.quoteImage
	}

	return (
		<Layout showContactForm={true}>
			<Headline {...headlineObj} />
			{!!project?.cards?.length && project.cards.map((card, index) => (
				<Card {...card} key={`project-${index}`} order={index+1} />
			))}
			<Statistics {...statsObj} />
			<AdditionalContent {...additionalContentObj} />
			<Quote {...quoteObj} />
		</Layout>
	);
}

const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="ProjectsProject" apiParams={{permalink: props.params.permalink||props.pageContext.permalink}}><ProjectDetail {...props} /></DataProvider>
export default getData;

export const query = graphql`
query ($permalink: String) {
	allProjectsProject(
	  filter: {permalink: {eq: $permalink}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
			title
			description
			longDescription
			image

			cards

			statsTitle
			statsBody
			stats

			additionalTitle
			additionalSubTitle
			additionalContent

			quoteImage
			quote
			quotee
		}
	  }
	}
  }
`