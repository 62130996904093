import * as React from "react"

const ProjectStatistics = props => {

	if (!props.title && !props.body && !props.stats?.length) return null;

	return (
		<section id="stats">
			<div className="grid-container">
				<div className="grid-padding-x grid-x align-center">
					<div className="cell small-12 medium-10">
						<div className="grid-x align-middle">
							<div className="title">{props.title}</div>
							<div className="body">{props.body}</div>
						</div>
						<hr />
						<div className="grid-x grid-margin-x stats-wrapper small-up-1 medium-up-3 large-up-4">
							{!!props.stats?.length && props.stats.map((stat, index) => (
								<div key={`stat-${index}`} className="cell">
									<div className="stat">{stat.stat}</div>
									<div className="description">{stat.description}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ProjectStatistics;