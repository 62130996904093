import * as React from "react"

const ProjectQuote = props => {
	if (!props.quote) return null;

	return (
		<section id="quote">
			<div className="grid-container">
				<div className="grid-padding-x grid-x align-middle">
					{!!props.image &&
						<div className="cell small-12 medium-3 medium-offset-1">
							<div className="image-wrapper" style={{ backgroundImage: `url('${props.image}')` }}></div>
						</div>
					}
					<div className="cell small-12 medium-6 medium-offset-1">
						<div className="quote">“</div>
						<p>{props.quote}</p>
						{props.quotee && <span>{props.quotee}</span>}
					</div>
				</div>
			</div>
		</section>
	);
}

export default ProjectQuote;