import * as React from "react"

import zparse from 'helpers/zparse';

const ProjectAdditionalContent = props => {
	return (
		<section id="additionalContent">
			<div className="grid-container">
				<div className="grid-padding-x grid-x align-middle">
					<div className="cell auto">
						<div className="title-wrapper">
							<h3>
								<span>{props.title}</span>
								{props.subTitle}
							</h3>
						</div>
					</div>
					<div className="cell small-12 medium-7 content-section">
						{zparse(props.content)}
					</div>
				</div>
			</div>
		</section>
	);
}

export default ProjectAdditionalContent;